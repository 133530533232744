<template>
  <div>
    <v-color-picker
      v-model="item.Value"
      mode="hexa"
      :disabled=" $helpers.itemNeedsDisable(item.ReadOnly, needsDisable, editItemAdmin)"
      dot-size="25"
      swatches-max-height="200"
    ></v-color-picker>
    <v-row no-gutters v-if="$store.getters.editMode">
      <v-col cols="12">
        <hr />
      </v-col>
      <v-switch v-model="item.ReadOnly" :label="$t('Read-only')"></v-switch>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: [Array, Object],
    },
    needsDisable: {
      type: Boolean,
    },
    editItemAdmin: {
      type: Boolean,
    }
  },
};
</script>

<style>
</style>